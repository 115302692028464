import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from '../Header/index';
import Footer from '../footer';
import Layout from '../layout';
import Seo from '../seo';
import { getPreviewPage } from '../../state/app';
import PreviewArticleDetail from './PreviewArticleDetail';
import Breadcrumbs from '../BlockLibrary/Healthzone/Breadcrumbs';

const Article = ({ article, isLoading }) => {
     const seoTitleTag = article.fields.seoTitleTag ? article.fields.seoTitleTag : `${article.fields.breadcrumbValue} | Pet Health Insurance & Tips`;
     const seoMetaDescription = article.fields.seoMetaDescription ? article.fields.seoMetaDescription : article.fields.shortDescription;

     return (
          <>
               <Layout>
                    <Seo
                         title={seoTitleTag}
                         canonical={article.fields.seoCanonicalUrl}
                         description={seoMetaDescription}
                         robots={article.fields.robotsDirective}
                         image={article.fields.socialImage ? article.fields.socialImage : article.fields.featuredImage}
                    />
                    {
                         isLoading && (
                              <div className="page-loading">Loading ...</div>
                         )
                    }
                    <Header specificPhone={article.fields.specificPhone} header={article.fields.header}></Header>
                    <Breadcrumbs article={article.fields} category={article.fields.category}></Breadcrumbs>
                    <PreviewArticleDetail article={article.fields} />
                    <Footer footer={article.fields.footer}></Footer>
               </Layout>
          </>
     )
}


Article.propTypes = {
     article: PropTypes.object,
}

function mapStateToProps(state) {
     return {
          article: getPreviewPage(state)
     };
}

export default connect(mapStateToProps)(Article);
