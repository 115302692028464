import React, { Component } from 'react';
import Slider from 'react-slick';
import { RichText } from '../richTextOptions';
import { Image } from '../BlockLibrary/Common/Image';
// import NotOverrideArticleSidebar from './ArticleSidebar/NotOverrideArticleSidebar';
// import OverrideArticleSidebar from './ArticleSidebar/OverrideArticleSidebar';


class PreviewArticleDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            emailAddress: '',
            slideIndex: 0
        }
    }

    onFirstNameChanged = (e) => this.setState({ firstName: e.target.value })
    onEmailAddressChanged = (e) => this.setState({ emailAddress: e.target.value })

    render() {
        const { article } = this.props;
        const { slideIndex } = this.state;
        const settings = {
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 600,
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: true,
            beforeChange: (current, next) => this.setState({ slideIndex: next })
        };

        if (article.photos) {
            var currentImageContent = article.photos[slideIndex].fields;
        }

        return (
            <>
                <section className={`article-content`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                {article.contentName && <h1>{article.contentName}</h1>}
                                {
                                    article.photos ? (
                                        <>
                                            <div className="body-content-wrap">
                                                {article.body && <RichText data={article.body}></RichText>}
                                            </div>
                                            <div className="article-slider">
                                                <Slider ref={slider => (this.sliderArticle = slider)} {...settings}>
                                                    {
                                                        article.photos.map((photo, i) => {
                                                            return (<div key={i}><Image image={photo} /></div>)
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                            <ul className="slider-thumbs horizontal-scroll">
                                                {
                                                    article.photos.map((photo, i) => {
                                                        return (
                                                            <li key={i} className={`slider-navigation ${(slideIndex == i) ? 'active' : ''}`} >
                                                                <img loading="lazy"
                                                                    src={photo.fields.file.url}
                                                                    alt={(photo.fields.description === '' || photo.fields.description === null) ? photo.fields.title : photo.fields.description}
                                                                    className="img-slider-responsive"
                                                                    onMouseEnter={() => this.sliderArticle.slickGoTo(i)}
                                                                    onClick={() => this.sliderArticle.slickGoTo(i)}
                                                                />
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <div className="slider-description">
                                                <div className="description">
                                                    <h2>{slideIndex + 1}. {currentImageContent.title}</h2>
                                                    <p>{currentImageContent.description}</p>
                                                    <p className="carousel-index">{slideIndex + 1} of {article.photos.length}</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                            <>
                                                {
                                                    !article.isInfographic && (
                                                        <div className={`${article.featuredImage ? 'healthzone-intro-image' : ''}`}>
                                                            <Image image={article.featuredImage} />
                                                        </div>
                                                    )
                                                }
                                                <div className="body-content-wrap">
                                                    {article.body && <RichText data={article.body}></RichText>}
                                                </div>
                                            </>
                                        )
                                }

                                <div className="share-article">
                                    <p>Share this article on</p>
                                    <div className="social">
                                        <ul>
                                            <li>
                                                <a target="_blank" href="https://www.facebook.com/NationwidePet/">
                                                    <img loading="lazy" src="/images/healthzone-social-icon/facebook.png" alt='facebook' />
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="https://twitter.com/NationwidePet">
                                                    <img loading="lazy" src="/images/healthzone-social-icon/twitter.png" alt='twitter' />
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="https://www.instagram.com/nationwidepet/">
                                                    <img loading="lazy" src="/images/healthzone-social-icon/instagram.png" alt='instagram' />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 healthzone-subcribe-form">
                                <div className="article-sidebar">
                                    <div className="share-article">
                                        <p>Share this article on</p>
                                        <div className="social">
                                            <ul>
                                                <li>
                                                    <a target="_blank" href="https://www.facebook.com/NationwidePet/">
                                                        <img loading="lazy" src="/images/healthzone-social-icon/facebook.png" alt='facebook' />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://twitter.com/NationwidePet">
                                                        <img loading="lazy" src="/images/healthzone-social-icon/twitter.png" alt='twitter' />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.instagram.com/nationwidepet/">
                                                        <img loading="lazy" src="/images/healthzone-social-icon/instagram.png" alt='instagram' />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <form>
                                        <h4>Get Informative Articles Delivered Weekly</h4>
                                        <div className="form-group">
                                            <label htmlFor="firstName">First name</label>
                                            <input
                                                id="firstName"
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                value={this.state.firstName}
                                                onChange={this.onFirstNameChanged}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="emailAddress">Enter email address</label>
                                            <input
                                                id="emailAddress"
                                                type="email"
                                                className="form-control"
                                                id="emailAddress"
                                                value={this.state.emailAddress}
                                                onChange={this.onEmailAddressChanged}
                                            />
                                        </div>
                                        <p>By subscribing, you agree to our Terms of Use and Privacy Policy.</p>
                                        <button type="submit" className="subcribe-btn">Subscribe</button>
                                    </form> */}
                                    {/* {
                                        article.sidebarArticles ? (
                                            <OverrideArticleSidebar sidebarArticles={article.sidebarArticles} />
                                        ) : (
                                                <NotOverrideArticleSidebar
                                                    currentArticle={article}
                                                    relatedArticles={relatedArticles}
                                                    infographicArticle={infographicArticle}
                                                />
                                            )
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default PreviewArticleDetail;
